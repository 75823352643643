import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';

import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import * as validators from '../../../util/validators';
import { Form, PrimaryButton, FieldTextInput, NamedLink } from '../../../components';

import css from './LoginForm.module.css';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { SocialLoginButtonsMaybe } from '../AuthenticationPage';


const LoginFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {


      const location = useLocation();
      
      const path = location.pathname;
      //const url = window.location.pathname.split('/').pop();

      const {
        rootClassName,
        className,
        formId,
        handleSubmit,
        inProgress,
        intl,
        invalid,
        hideAccountCreation,
        handleCloseForm,

        isLogin,
        showFacebookLogin,
        showGoogleLogin,
        from,


      } = fieldRenderProps;

      const sideForm = useRef(null);
      useEffect(
        ()=>{
  
          if(path==="/side-menu" && sideForm.current != null){
            console.log("Animate side form ----------------------------");
           
          }

            },[]
      );

      // email---
      const emailLabel = intl.formatMessage({
        id: 'LoginForm.emailLabel',
      });
      const emailPlaceholder = intl.formatMessage({
        id: 'LoginForm.emailPlaceholder',
      });
      const emailRequiredMessage = intl.formatMessage({
        id: 'LoginForm.emailRequired',
      });
      const emailRequired = validators.required(emailRequiredMessage);
      const emailInvalidMessage = intl.formatMessage({
        id: 'LoginForm.emailInvalid',
      });
      const emailValid = validators.emailFormatValid(emailInvalidMessage);

      // password
      const passwordLabel = intl.formatMessage({
        id: 'LoginForm.passwordLabel',
      });
      const passwordPlaceholder = intl.formatMessage({
        id: 'LoginForm.passwordPlaceholder',
      });
      const passwordRequiredMessage = intl.formatMessage({
        id: 'LoginForm.passwordRequired',
      });
      const passwordRequired = validators.requiredStringNoTrim(passwordRequiredMessage);

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const submitDisabled = invalid || submitInProgress;

      const passwordRecoveryLink = (
        <NamedLink name="PasswordRecoveryPage" className={css.recoveryLink}>
          <FormattedMessage id="LoginForm.forgotPassword" />
        </NamedLink>
      );

      
//
     
      return (
        <div ref={sideForm}>
          <Form className={classes} onSubmit={handleSubmit} >
                    <div>
                      <h4 className={css.formHeader}>LOGIN</h4>
                      <FieldTextInput
                        type="email"
                        id={formId ? `${formId}.email` : 'email'}
                        name="email"
                        autoComplete="email"
                        label={emailLabel}
                        placeholder={emailPlaceholder}
                        validate={validators.composeValidators(emailRequired, emailValid)}
                      />
                      <FieldTextInput
                        className={classNames(css.password,css.noBold)}
                        type="password"
                        id={formId ? `${formId}.password` : 'password'}
                        name="password"
                        autoComplete="current-password"
                        label={passwordLabel}
                        placeholder={passwordPlaceholder}
                        validate={passwordRequired}
                      />

                    

                        <div className={css.marginTop20}>
                        <p>
                          <span className={css.recoveryLinkInfo}>
                            <FormattedMessage
                              id="LoginForm.forgotPasswordInfo"
                              values={{ passwordRecoveryLink }}
                            />
                          </span>
                        </p>
                        <PrimaryButton type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
                          <FormattedMessage id="LoginForm.logIn" />
                        </PrimaryButton>
                      </div>

                      <div className={css.orLoginWith}>
                        <hr/><span>OR LOGIN WITH</span><hr/>
                      </div>

                     
                     

                      
                    </div>

                  

                  
                    
                  </Form>

                  {hideAccountCreation?
                          <SocialLoginButtonsMaybe
                            isLogin={true}
                            showFacebookLogin={true}
                            showGoogleLogin={true}
                            from={from}
                          />:""}
                 

                      {hideAccountCreation?
                        <div className={css.createAccount}> 
                        
                          <hr/>
                          <svg className={css.svgColor} xmlns="http://www.w3.org/2000/svg" width="80" height="80" fill="black" class="bi bi-person" viewBox="0 0 16 16">
                            <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0m4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4m-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10s-3.516.68-4.168 1.332c-.678.678-.83 1.418-.832 1.664z"/>
                          </svg>
                          <p>No account yet?</p>
                            <NamedLink name='SignupPage'>
                              CREATE ACCOUNT
                            </NamedLink>
                          <hr/>
                        </div>
                      :
                      ""
                      }
                      

        </div>
       
      );
    }}
  />
);

LoginFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  form: null,
  inProgress: false,
};

const { string, bool } = PropTypes;

LoginFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  form: string,
  inProgress: bool,
  intl: intlShape.isRequired,
};

const LoginForm = compose(injectIntl)(LoginFormComponent);
LoginForm.displayName = 'LoginForm';

export default LoginForm;
