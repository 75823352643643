import React, { useState } from 'react';
import { bool, node } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import classNames from 'classnames';

import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import * as validators from '../../../util/validators';
import { Form, PrimaryButton, FieldTextInput, FieldRadioButton, FieldSelect } from '../../../components';

import css from './SignupForm.module.css';

const SignupFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        formId,
        handleSubmit,
        inProgress,
        invalid,
        intl,
        termsAndConditions,
        handleSetShopUrl,
        shopUrl,
      } = fieldRenderProps;

      const [show,setShow] = useState(false);
      
      const HandleChange = (event)=>{
          if(event.target.value==="Vendor"){
              setShow(true);
          }else{
            setShow(false)
          }
        }

      // email
      const emailRequired = validators.required(
        intl.formatMessage({
          id: 'SignupForm.emailRequired',
        })
      );

      const emailValid = validators.emailFormatValid(
        intl.formatMessage({
          id: 'SignupForm.emailInvalid',
        })
      );

      const handleTxtChange = (event)=>{
        console.log(event.target.value);
        handleSetShopUrl(event.target.value);
        
      }

      // password
      const passwordRequiredMessage = intl.formatMessage({
        id: 'SignupForm.passwordRequired',
      });
      const passwordMinLengthMessage = intl.formatMessage(
        {
          id: 'SignupForm.passwordTooShort',
        },
        {
          minLength: validators.PASSWORD_MIN_LENGTH,
        }
      );
      const passwordMaxLengthMessage = intl.formatMessage(
        {
          id: 'SignupForm.passwordTooLong',
        },
        {
          maxLength: validators.PASSWORD_MAX_LENGTH,
        }
      );
      const passwordMinLength = validators.minLength(
        passwordMinLengthMessage,
        validators.PASSWORD_MIN_LENGTH
      );
      const passwordMaxLength = validators.maxLength(
        passwordMaxLengthMessage,
        validators.PASSWORD_MAX_LENGTH
      );
      const passwordRequired = validators.requiredStringNoTrim(passwordRequiredMessage);
      const passwordValidators = validators.composeValidators(
        passwordRequired,
        passwordMinLength,
        passwordMaxLength
      );

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const submitDisabled = invalid || submitInProgress;
      const showAsRequired = invalid || submitInProgress;

      const otherInfoFields = show?
      
                        <div className={css.otherInfo}>
                          

                          <FieldTextInput
                            className={css.otherInput}
                            type="text"
                            id={formId ? `${formId}.shopName` : 'shopName'}
                            name="shopName"
                            autoComplete="given-name"
                            label={intl.formatMessage({
                              id: 'SignupForm.shopNameLabel',
                            })}
                            placeholder={intl.formatMessage({
                              id: 'SignupForm.shopNamePlaceholder',
                            })}
                            validate={validators.required(
                              intl.formatMessage({
                                id: 'SignupForm.shopNameRequired',
                              })
                            )}
                          />

                         

                          <div>
                              <label for="shopUrl">Shop Url</label>
                              <input name="shopUrl" id={formId ? `${formId}.shopUrl` : 'shopUrl'} type='text' onChange={handleTxtChange} value={shopUrl===false?"":shopUrl}/>
                              <label>{shopUrl?"https://buyselldance.com/store/"+shopUrl:"https://buyselldance.com/store/"}</label>
                          </div>
                          
                          <FieldTextInput
                            className={css.otherInput}
                            type="text"
                            id={formId ? `${formId}.street` : 'street'}
                            name="street"
                            autoComplete="given-name"
                            label={intl.formatMessage({
                              id: 'SignupForm.streetLabel',
                            })}
                            placeholder={intl.formatMessage({
                              id: 'SignupForm.streetPlaceholder',
                            })}
                            validate={validators.required(
                              intl.formatMessage({
                                id: 'SignupForm.streetRequired',
                              })
                            )}
                          />

                          <FieldTextInput
                            className={css.otherInput}
                            type="text"
                            id={formId ? `${formId}.street2` : 'street2'}
                            name="street2"
                            autoComplete="given-name"
                            label={intl.formatMessage({
                              id: 'SignupForm.streetLabel2',
                            })}
                            placeholder={intl.formatMessage({
                              id: 'SignupForm.streetPlaceholder2',
                            })}
                            validate={validators.required(
                              intl.formatMessage({
                                id: 'SignupForm.streetRequired2',
                              })
                            )}
                          />

                          <div className={css.name}>
                            <FieldTextInput
                              className={css.firstNameRoot}
                              type="text"
                              id={formId ? `${formId}.city` : 'city'}
                              name="city"
                              autoComplete="given-name"
                              label={intl.formatMessage({
                                id: 'SignupForm.cityLabel',
                              })}
                              placeholder={intl.formatMessage({
                                id: 'SignupForm.cityPlaceholder',
                              })}
                              validate={validators.required(
                                intl.formatMessage({
                                  id: 'SignupForm.cityRequired',
                                })
                              )}
                            />
                            <FieldTextInput
                              className={css.lastNameRoot}
                              type="text"
                              id={formId ? `${formId}.postZip` : 'postZip'}
                              name="postZip"
                              autoComplete="family-name"
                              label={intl.formatMessage({
                                id: 'SignupForm.postZipLabel',
                              })}
                              placeholder={intl.formatMessage({
                                id: 'SignupForm.postZipPlaceholder',
                              })}
                              validate={validators.required(
                                intl.formatMessage({
                                  id: 'SignupForm.postZipRequired',
                                })
                              )}
                            />
                          </div>

                          <FieldSelect id="country" name="country" label="Country:">
                            <option value="">- Select a location -</option>
                            <option value="first">Canada</option>
                            <option value="second">United States(US)</option>
                          </FieldSelect>

                          <FieldTextInput
                            className={css.otherInput}
                            type="text"
                            id={formId ? `${formId}.state` : 'state'}
                            name="state"
                            autoComplete="given-name"
                            label={intl.formatMessage({
                              id: 'SignupForm.stateLabel',
                            })}
                            placeholder={intl.formatMessage({
                              id: 'SignupForm.statePlaceholder',
                            })}
                            validate={validators.required(
                              intl.formatMessage({
                                id: 'SignupForm.stateRequired',
                              })
                            )}
                          />

                          <FieldTextInput
                            className={css.otherInput}
                            type="text"
                            id={formId ? `${formId}.phone` : 'phone'}
                            name="phone"
                            autoComplete="given-name"
                            label={intl.formatMessage({
                              id: 'SignupForm.phoneLabel',
                            })}
                            placeholder={intl.formatMessage({
                              id: 'SignupForm.phonePlaceholder',
                            })}
                            validate={validators.required(
                              intl.formatMessage({
                                id: 'SignupForm.phoneRequired',
                              })
                            )}
                          />
                          
                        </div>
      
                        :"";

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <div>
            <h4 className={css.formHeader}>REGISTER</h4>
            <FieldTextInput
              type="email"
              id={formId ? `${formId}.email` : 'email'}
              name="email"
              autoComplete="email"
              label={intl.formatMessage({
                id: 'SignupForm.emailLabel',
              })}
              placeholder={intl.formatMessage({
                id: 'SignupForm.emailPlaceholder',
              })}
              validate={validators.composeValidators(emailRequired, emailValid)}
            />

            <FieldTextInput
              className={css.otherInputMain}
              type="text"
              id={formId ? `${formId}.fname` : 'fname'}
              name="fname"
              autoComplete="given-name"
              label={intl.formatMessage({
                id: 'SignupForm.firstNameLabel',
              })}
              placeholder={intl.formatMessage({
                id: 'SignupForm.firstNamePlaceholder',
              })}
              validate={validators.required(
                intl.formatMessage({
                  id: 'SignupForm.firstNameRequired',
                })
              )}
            />

            <FieldTextInput
              className={css.otherInputMain}
              type="text"
              id={formId ? `${formId}.lname` : 'lname'}
              name="lname"
              autoComplete="family-name"
              label={intl.formatMessage({
                id: 'SignupForm.lastNameLabel',
              })}
              placeholder={intl.formatMessage({
                id: 'SignupForm.lastNamePlaceholder',
              })}
              validate={validators.required(
                intl.formatMessage({
                  id: 'SignupForm.lastNameRequired',
                })
              )}
            />
          
            <FieldTextInput
              className={css.password}
              type="password"
              id={formId ? `${formId}.password` : 'password'}
              name="password"
              autoComplete="new-password"
              label={intl.formatMessage({
                id: 'SignupForm.passwordLabel',
              })}
              placeholder={intl.formatMessage({
                id: 'SignupForm.passwordPlaceholder',
              })}
              validate={passwordValidators}
            />

           {otherInfoFields}

            <div  onChange={HandleChange} className={css.radioContainer}>
                <FieldRadioButton
                  id='SignupForm.RoleSeller'
                  name="role"
                  label="I am a customer"
                  value="Customer"
                  showAsRequired={showAsRequired}
                 
                />
                
                <FieldRadioButton
                  id='SignupForm.Influencer'
                  name="role"
                  label="I am a vendor"
                  value="Vendor"
                  showAsRequired={showAsRequired}
                  
                />
            </div>

            <p className={css.textSize}>
              Your personal data will be used to support your experience throughout this website, 
              to manage access to your account, and for other purposes described in our privacy policy.
            </p>

          </div>

          <div className={css.bottomWrapper}>
            {termsAndConditions}
            <PrimaryButton type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
              <FormattedMessage id="SignupForm.signUp" />
            </PrimaryButton>
          </div>
        </Form>
      );
    }}
  />
);

SignupFormComponent.defaultProps = { inProgress: false };

SignupFormComponent.propTypes = {
  inProgress: bool,
  termsAndConditions: node.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const SignupForm = compose(injectIntl)(SignupFormComponent);
SignupForm.displayName = 'SignupForm';

export default SignupForm;
